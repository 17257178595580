<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <HomeStayForm></HomeStayForm>
          
          </div>
          <div class="vx-card p-6 mb-6">
            <SeoInfo></SeoInfo>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <AdditionInfo></AdditionInfo>
            </div>
            <div class="vx-card p-6 mb-6">
              <HomeStayImage></HomeStayImage>
            </div>
        </div>
       </div>

        <div class="vx-card p-4 mb-4">
              <LocationInfo></LocationInfo>
        </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import HomeStayForm from './common/HomeStayForm';
import AdditionInfo from './common/AdditionInfo';
import SettingInfo from './common/SettingInfo';
import LocationInfo from './common/LocationInfo';
import SeoInfo from './common/SeoInfo';
import HomeStayImage from './common/HomeStayImage';
import {HOMESTAY} from '../../../constant/entity-identifier';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

import {storeImage} from '../../../services/image';

export default {
  components: {
    vSelect,
    HomeStayForm,
    AdditionInfo,
    LocationInfo,
    SeoInfo,
    SettingInfo,
    HomeStayImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'homestay/getFormObj',
        homestayImages: 'homestay/getHomeStayImages',
    })
  },
  created() {
      this.clearForm();
      this.clearValidationErrors();
    },
  methods: {
    ...mapActions({
      createHomeStay: 'homestay/createHomeStay',
      clearForm: 'homestay/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    prepareForm(id, type) {
      let formData = new FormData();
      formData.append("entity_id", id);
      formData.append("entity", type);
      for (let i = 0; i < this.homestayImages.length; i++) {
          formData.append("images[" + i + "]", this.homestayImages[i].image);
          formData.append("alt[" + i + "]", this.homestayImages[i].alt);
      }
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.createHomeStay(this.form)
        .then(async response => {
           if(response.status === 201) {
             if(this.homestayImages.length > 0) {
                await storeImage(this.prepareForm(response.data.data.id, HOMESTAY));
                this.closeLoading();
             }
            this.$vs.notify({ 
                title: 'Homestay Created',
                text: 'Homestay created successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push('/homestays');
           }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
